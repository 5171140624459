/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, Image, ColumnDiv } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pt--0" menu={true} style={{"paddingBottom":35.96875}}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box">
                
                <Subtitle className="subtitle-box ff--2 fs--48 subtitle-box--invert pl--0 pr--0" content={"<span style=\"font-weight: bold;\">Hromosvody Filip</span>"}>
                </Subtitle>

                <Subtitle className="subtitle-box ff--2 fs--18 subtitle-box--invert pl--0 pr--0" content={"TEL: +420 603 755 149\n<br>EMAIL: filipales@seznam.cz"}>
                </Subtitle>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 ff--2 w--400 pl--08 pr--08" href={"#sluzby-1"} target={""} content={"Služby"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 ff--2 w--400 pl--08 pr--08" href={"#9dr4jnom56g"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 ff--2 w--400 pl--08 pr--08" href={"#gznfvucqjip"} target={""} content={"Naše práce"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 ff--2 w--400 pl--08 pr--08" href={"#paticka"} target={""} content={"Kontakt"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-1clh34y bg--center --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/2739/84a58e90550443069e299545e69c1986_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pt--0 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s4" anim={"7"} animS={"4"}>
              
              <Button className="btn-box btn-box--sbtn2 ff--2 fs--48 pb--0" content={"<br><br><span style=\"font-weight: bold;\"><br><br>Provádíme kompletní služby v oblasti montáží, oprav a revizí hromosvodů ve Východních Čechách a okolí.&nbsp;</span><br>"}>
              </Button>

              <Button className="btn-box btn-box--sbtn2 ff--2 fs--22 pb--0" content={"Jsme česká rodinná firma s třicetiletou tradicí. Za dobu své existence jsme nasbírali mnoho zkušeností, které Vám rádi předvedeme.&nbsp;&nbsp;<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"sluzby-1"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s4 el--1 pb--20 pt--20" anim={"5"} animS={"4"} style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box title-box--right ff--2 fs--48" content={"<span style=\"font-weight: bold;\">Provádíme</span>"}>
              </Title>

              <Text className="text-box" content={"- optimální řešení hromosvodu právě pro vaši střechu\n<br>- montážní práce týkající se uzemňovacích soustav a hromosvodů<br>- měření uzemnění\n<br>- revize hromosvodu<br>- opravu stávajícího vedení<br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--02 pt--02" name={"9dr4jnom56g"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--2 fs--48 pt--0" content={"<span style=\"font-weight: bold;\">Ceník</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--3 --full flex--center" style={{"maxWidth":1540}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/964cfef9603c4e4a9bf11e2d619eaeef_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2739/964cfef9603c4e4a9bf11e2d619eaeef_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/964cfef9603c4e4a9bf11e2d619eaeef_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/964cfef9603c4e4a9bf11e2d619eaeef_s=860x_.jpg 860w"} position={{"x":"-63.75845410628019%","y":"-39.46859903381644%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--bottom">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box ff--2" content={"<span style=\"font-weight: bold;\">od 7.000,—&nbsp;</span>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/02268b79223944d49dfc8c6ae1d7084a_s=660x_.jpg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2739/02268b79223944d49dfc8c6ae1d7084a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/02268b79223944d49dfc8c6ae1d7084a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/02268b79223944d49dfc8c6ae1d7084a_s=860x_.jpg 860w"} position={{"x":"-39.526570048309175%","y":"-0.1610305958132045%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--bottom">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box ff--2" content={"<span style=\"font-weight: bold;\">od 15.000,—&nbsp;</span>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/7d754dc654e24c329a506f87e3af27fe_s=660x_.jpeg"} svg={false} ratio={"2:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2739/7d754dc654e24c329a506f87e3af27fe_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/2739/7d754dc654e24c329a506f87e3af27fe_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/2739/7d754dc654e24c329a506f87e3af27fe_s=860x_.jpeg 860w"} position={{"x":"-85.74879227053138%","y":"-3.22061191626409%"}}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--bottom">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box ff--2 fs--20 lh--2" content={"<span style=\"font-weight: bold;\">dle konkrétního řešení</span>"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--02" name={"4wqna2aull2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--left" style={{"maxWidth":1333}} content={"<span style=\"font-weight: bold;\">Hromosvody </span>- jímací soustava hromosvodů na malém rodinném domě lze realizovat již za částku okolo 7 tis. Kč. Konkrétní kalkulace však závisí na mnoha faktorech: velikosti objektu, členitosti střechy, druhu krytiny, půdních podmínkách (tzv. rezistivitě půdy) a v neposlední řadě na druhu použitého instalačního materiálu.\n<br><span style=\"font-weight: bold;\">Revize </span>- cena na běžný rodinný domek začíná někde okolo 1000,- Kč. Opět závisí na velikosti objektu a zejména na počtu svodů k zemničům (od toho se odvíjí počet měření). <br><span style=\"font-weight: bold;\">&nbsp;Zvažujete-li montáž hromosvodu, neváhejte nás kontaktovat. Rádi vám poradíme, připravíme návrh řešení a cenovou kalkulaci.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--0 pt--06" name={"gznfvucqjip"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--2 fs--48" content={"<span style=\"font-weight: bold;\">Naše práce</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--0" name={"mnlsfzthxk"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/64d0cf4a39f840b9b8f443557b8a6f60_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/2739/64d0cf4a39f840b9b8f443557b8a6f60_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/64d0cf4a39f840b9b8f443557b8a6f60_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/64d0cf4a39f840b9b8f443557b8a6f60_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2739/64d0cf4a39f840b9b8f443557b8a6f60_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2739/64d0cf4a39f840b9b8f443557b8a6f60_s=2000x_.jpg 2000w"} position={{"x":"-55.94059405940595%","y":"-5.198019801980198%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/933d0e5cb170460f865c61bda3b6fff4_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/2739/933d0e5cb170460f865c61bda3b6fff4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/933d0e5cb170460f865c61bda3b6fff4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/933d0e5cb170460f865c61bda3b6fff4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2739/933d0e5cb170460f865c61bda3b6fff4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2739/933d0e5cb170460f865c61bda3b6fff4_s=2000x_.jpg 2000w"} position={{"x":"-49.99999999999999%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/099f423846684fbbb3ec075b4328d894_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/2739/099f423846684fbbb3ec075b4328d894_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/099f423846684fbbb3ec075b4328d894_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/099f423846684fbbb3ec075b4328d894_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2739/099f423846684fbbb3ec075b4328d894_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2739/099f423846684fbbb3ec075b4328d894_s=2000x_.jpg 2000w"} position={{"x":"-40.59405940594056%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/38488ecc50bd40a49497d5fb4607553c_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/2739/38488ecc50bd40a49497d5fb4607553c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/38488ecc50bd40a49497d5fb4607553c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/38488ecc50bd40a49497d5fb4607553c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2739/38488ecc50bd40a49497d5fb4607553c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2739/38488ecc50bd40a49497d5fb4607553c_s=2000x_.jpg 2000w"} position={{"x":"-4.653465346534656%","y":"-0.25742574257425743%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/f184accd9cba4650872dae8e76243b86_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/2739/f184accd9cba4650872dae8e76243b86_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/f184accd9cba4650872dae8e76243b86_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/f184accd9cba4650872dae8e76243b86_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2739/f184accd9cba4650872dae8e76243b86_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2739/f184accd9cba4650872dae8e76243b86_s=2000x_.jpg 2000w"} position={{"x":"-13.009900990099013%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2739/16aaeefd80114d279857dcbf2beb0ae0_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/2739/16aaeefd80114d279857dcbf2beb0ae0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2739/16aaeefd80114d279857dcbf2beb0ae0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2739/16aaeefd80114d279857dcbf2beb0ae0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2739/16aaeefd80114d279857dcbf2beb0ae0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2739/16aaeefd80114d279857dcbf2beb0ae0_s=2000x_.jpg 2000w"} position={{"x":"-59.15841584158416%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-yhcx0e --parallax pb--80 pt--80" name={"paticka"} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(17,20,28,0.8), rgba(17,20,28,0.8)), url(https://cdn.swbpg.com/t/2739/13c58234f4ad4026a53b132d2fd40a88_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--3 pb--20 pt--20 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box ff--2 fs--30 subtitle-box--invert lh--1" content={"HROMOSVODY FILIP"}>
              </Subtitle>

              <Text className="text-box ff--2 fs--18 text-box--invert" style={{"maxWidth":334}} content={"montáže hromosvodů ve Východních Čechách a okolí"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box ff--2 fs--30 title-box--invert" content={"KONTAKT"}>
              </Title>

              <Text className="text-box ff--2 fs--20 text-box--invert" style={{"maxWidth":395}} content={"<span style=\"font-weight: bold;\">Telefonní kontakt: +420 603 755 149 <br></span>&nbsp;E-mail: filipales@seznam.cz\n<br><br>Lesní E 89, Hradec Králové\n<br>IČ: 10472461\n<br>DIČ: CZ5512260083"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right ff--2 fs--24 subtitle-box--invert" content={"<a href=\"#uvod\">Zpět nahoru</a>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}